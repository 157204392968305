.page-header {
  background: rgb(69, 81, 244);
  background: linear-gradient(90deg, rgba(69, 81, 244, 1) 0%, rgba(53, 182, 231, 1) 100%);
  grid-area: header;

  .placeholder-dark {
    &::placeholder {
      color: var(--bs-text-dark);
      font-size: 1rem !important;
      font-weight: normal;
    }
  }
}