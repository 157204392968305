.home-view {
  .user-profile {
    color: var(--bs-white);

    @include media-breakpoint-up(md) {
      color: 1px solid var(--bs-black);
    }
  }

  .search-lemur, .nav-options {
    @include media-breakpoint-up(md) {
      border-left: 1px solid var(--bs-gray-400);
      border-right: 1px solid var(--bs-gray-400);
    }
  }
  .notifications {
    @include media-breakpoint-up(md) {
      border-left: 1px solid var(--bs-gray-400);
      border-right: 1px solid var(--bs-gray-400);
      border-bottom: 1px solid var(--bs-gray-400);
    }
  }
}