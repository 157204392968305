.condition-card__title {
    font-size: 1.5rem !important;
    color: white;
    font-weight: bold;
  }

  .condition-card__date {
    font-size: .7rem !important;
  }

  .condition-card__doc {
    font-size: .9rem !important;
  }

.condition-card {
  background-color: white;
}

.chevron-container {
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
}