.my-data {
  &__list {
    .accordion-header {
      margin-bottom: .5rem;

      .accordion-button {
        border-radius: .5rem !important;
        font-weight: 500;
        letter-spacing: .02rem;

        &.collapsed {
          border-radius: .5rem !important;
        }
      }
    }

    .accordion-item {
      background-color: transparent;
    }
  }

  .heading-row {
    color: var(--bs-white);

    @include media-breakpoint-up(md) {
      color: var(--bs-gray-900);
    }
  }
}