.side-nav {
  height: 100%;
  max-height: 60px;

  @include media-breakpoint-up(sm) {
    max-height: inherit;
  }

  &__nav {
    height: 90vh;
  }

  .nav-item:not(.position-fixed) {
    @include media-breakpoint-up(sm) {
      width: 100%;
    }
  }
}