// QwikCare Bootswatch theme

/*
https://qwikcare.wpengine.com/#
https://www.figma.com/community/file/1026733583562048041/healthcare-dashboard
--wp--preset--color--black: #000000;
    --wp--preset--color--cyan-bluish-gray: #abb8c3;
    --wp--preset--color--white: #ffffff;
    --wp--preset--color--pale-pink: #f78da7;
    --wp--preset--color--vivid-red: #cf2e2e;
    --wp--preset--color--luminous-vivid-orange: #ff6900;
    --wp--preset--color--luminous-vivid-amber: #fcb900;
    --wp--preset--color--light-green-cyan: #7bdcb5;
    --wp--preset--color--vivid-green-cyan: #00d084;
    --wp--preset--color--pale-cyan-blue: #8ed1fc;
    --wp--preset--color--vivid-cyan-blue: #0693e3;
    --wp--preset--color--vivid-purple: #9b51e0;
    --wp--preset--gradient--vivid-cyan-blue-to-vivid-purple: linear-gradient(135deg,rgba(6,147,227,1) 0%,rgb(155,81,224) 100%);
    --wp--preset--gradient--light-green-cyan-to-vivid-green-cyan: linear-gradient(135deg,rgb(122,220,180) 0%,rgb(0,208,130) 100%);
    --wp--preset--gradient--luminous-vivid-amber-to-luminous-vivid-orange: linear-gradient(135deg,rgba(252,185,0,1) 0%,rgba(255,105,0,1) 100%);
    --wp--preset--gradient--luminous-vivid-orange-to-vivid-red: linear-gradient(135deg,rgba(255,105,0,1) 0%,rgb(207,46,46) 100%);
    --wp--preset--gradient--very-light-gray-to-cyan-bluish-gray: linear-gradient(135deg,rgb(238,238,238) 0%,rgb(169,184,195) 100%);
    --wp--preset--gradient--cool-to-warm-spectrum: linear-gradient(135deg,rgb(74,234,220) 0%,rgb(151,120,209) 20%,rgb(207,42,186) 40%,rgb(238,44,130) 60%,rgb(251,105,98) 80%,rgb(254,248,76) 100%);
    --wp--preset--gradient--blush-light-purple: linear-gradient(135deg,rgb(255,206,236) 0%,rgb(152,150,240) 100%);
    --wp--preset--gradient--blush-bordeaux: linear-gradient(135deg,rgb(254,205,165) 0%,rgb(254,45,45) 50%,rgb(107,0,62) 100%);
    --wp--preset--gradient--luminous-dusk: linear-gradient(135deg,rgb(255,203,112) 0%,rgb(199,81,192) 50%,rgb(65,88,208) 100%);
    --wp--preset--gradient--pale-ocean: linear-gradient(135deg,rgb(255,245,203) 0%,rgb(182,227,212) 50%,rgb(51,167,181) 100%);
    --wp--preset--gradient--electric-grass: linear-gradient(135deg,rgb(202,248,128) 0%,rgb(113,206,126) 100%);
    --wp--preset--gradient--midnight: linear-gradient(135deg,rgb(2,3,129) 0%,rgb(40,116,252) 100%);
    --wp--preset--font-size--small: 13px;
    --wp--preset--font-size--medium: 20px;
    --wp--preset--font-size--large: 36px;
    --wp--preset--font-size--x-large: 42px;
    --wp--preset--spacing--20: 0.44rem;
    --wp--preset--spacing--30: 0.67rem;
    --wp--preset--spacing--40: 1rem;
    --wp--preset--spacing--50: 1.5rem;
    --wp--preset--spacing--60: 2.25rem;
    --wp--preset--spacing--70: 3.38rem;
    --wp--preset--spacing--80: 5.06rem;
    --wp--preset--shadow--natural: 6px 6px 9px rgba(0, 0, 0, 0.2);
    --wp--preset--shadow--deep: 12px 12px 50px rgba(0, 0, 0, 0.4);
    --wp--preset--shadow--sharp: 6px 6px 0px rgba(0, 0, 0, 0.2);
    --wp--preset--shadow--outlined: 6px 6px 0px -3px rgba(255, 255, 255, 1), 6px 6px rgba(0, 0, 0, 1);
    --wp--preset--shadow--crisp: 6px 6px 0px rgba(0, 0, 0, 1);
*/

$theme: "qwikcare" !default;

//
// Color system
//

$white:    #fff !default;
$gray-100: #f8f9fa !default;
// $gray-200: #eee !default;
$gray-300: #dee2e6 !default;
$gray-400: #ccc !default;
$gray-500: #adb5bd !default;
$gray-600: #888 !default;
$gray-700: #495057 !default;
// $gray-800: #333 !default;
$gray-900: #222 !default;
$black:    #000 !default;

// $blue:    #0047BA !default; // From theme
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
// $pink:    #e83e8c !default;
$pink:    #CF3B60 !default; // From theme
// $red:     #f04124 !default;
// $red:     #C40D3D !default; // From theme
$orange:  #fd7e14 !default;
$yellow:  #e99002 !default;
// $green:   #43ac6a !default;
$teal:    #20c997 !default;
// $cyan:    #5bc0de !default;
// $cyan:    #3A8DDE !default; // From theme

// From App
$gray-200: #F2F2F2 !default; // From App
$gray-800: #AFAEAF !default; // From App
$cyan: #35B6E7 !default; // From App
$red:     #E1445B !default; // From App
$blue: #4551F4 !default; // From App
$green: #72CEDD !default; // From App

$primary:       $blue !default;
$secondary:     $gray-200 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-200 !default;
$dark:          $gray-800 !default;

$min-contrast-ratio:   1.9 !default;

// Accordion

$accordion-button-gradient: gradient-bg(vertical, $green, #4BC3B4);
$accordion-button-bg: $green !default;
$accordion-button-color: $white !default;
$accordion-button-active-bg: $green !default;
$accordion-button-active-color: $white !default;
$accordion-button-padding-y: .5rem !default;
$accordion-button-padding-x: 1.5rem !default;

$accordion-icon-color: $white !default;
$accordion-icon-active-color: $white !default;

$accordion-border-width: 0 !default;



// Components

$border-radius:               .5rem !default;
$border-radius-lg:            2rem !default;
$border-radius-sm:            .5rem !default;

// Fonts

// stylelint-disable-next-line value-keyword-case
$font-family-sans-serif:      "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$headings-font-weight:        300 !default;

// Tables

$table-bg-scale:              0% !default;

// Buttons

$input-btn-padding-x:         .5rem !default;
$btn-font-weight:             300 !default;

// Dropdowns

$dropdown-border-color:             rgba($black, .1) !default;
$dropdown-divider-bg:               rgba($black, .1) !default;

// Navbar

$navbar-dark-color:                 rgba($white, .7) !default;
$navbar-dark-hover-color:           $white !default;
$navbar-light-toggler-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$white}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
$navbar-toggler-focus-width:        0 !default;

// Nav
$nav-pills-link-active-bg:          $info !default;
$nav-link-color:                    $light !default;
$nav-link-hover-color:              $info !default;

// Pagination

$pagination-color:                  $gray-600 !default;
// $pagination-border-color:           $nav-tabs-border-color !default;
$pagination-active-border-color:    darken($primary, 5%) !default;
$pagination-disabled-bg:            transparent !default;
$pagination-disabled-color:         $gray-400 !default;

// Cards

$card-inner-border-radius:          0 !default;

// Badges

$badge-font-weight:                 300 !default;
$badge-padding-x:                   1rem !default;

// Alerts

$alert-link-font-weight:            400 !default;

// Breadcrumbs

$breadcrumb-padding-y:              .375rem !default;
$breadcrumb-padding-x:              .75rem !default;
$breadcrumb-border-radius:          3px !default;

// Close

$btn-close-color:            $gray-600 !default;
$btn-close-opacity:          .6 !default;
$btn-close-hover-opacity:    1 !default;
