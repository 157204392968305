.site-layout {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "sidebar header"
    "sidebar main";
}

.content-wrapper {
  @include media-breakpoint-down(sm) {
    padding: 0;
  }

  .site-main {
    grid-area: main;
  }
}