.my-data {
    &__list {
      .accordion-header {
        margin-bottom: .5rem;
  
        .accordion-button {
          border-radius: .5rem !important;
          font-weight: 500;
          letter-spacing: .02rem;
  
          &.collapsed {
            border-radius: .5rem !important;
          }
        }
      }
  
      .accordion-item {
        background-color: transparent;
      }
    }
  
    .heading-row {
      color: var(--bs-white);
  
      @include media-breakpoint-up(md) {
        color: var(--bs-gray-900);
      }
    }
  }

  .search-lemur, .nav-options {
    @include media-breakpoint-up(md) {
      border-left: 1px solid var(--bs-gray-400);
      border-right: 1px solid var(--bs-gray-400);
    }
  }