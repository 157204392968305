.diagnoses-card__title {
    font-size: 1.5rem !important;
    color: white;
    font-weight: bold;
  }

  .diagnoses-card__date {
    font-size: .7rem !important;
    color: white;
  }

.diagnoses-card {
  background-color: #5e63f1;
  color:#5e63f1;
}

.chevron-container {
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
}