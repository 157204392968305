.overlay {
    background-color: rgba(51, 51, 51, .65);
    border: none;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;

    &Button:hover {
        background-color: var(--bs-primary);
    }

    &-content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}