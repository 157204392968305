.perscription-purchase-view {
  .list-group-item {
    &.active {
      background-color: var(--bs-info-bg-subtle) !important;
      border-color: var(--bs-info) !important;

      .perscription-header {
        color: var(--bs-info);
      }

      .perscription-price {
        color: var(--bs-info);
      }
    }
  }
}