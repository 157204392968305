.sync {
  width: 48px;
  height: 48px;
  display: inline-block;
  position: relative;
  border: 3px solid;
  border-color:#de3500 #0000 var(--bs-primary) #0000;
  border-radius: 50%;
  box-sizing: border-box;
  animation: 1s rotate linear infinite;

  &:before, &:after {
    content: '';
    top: 0;
    left: 0;
    position: absolute;
    border: 10px solid transparent;
    border-bottom-color: var(--bs-primary);
    transform: translate(-10px, 19px) rotate(-35deg);
  }

  &:after {
    border-color: #de3500 #0000 #0000 #0000 ;
    transform: translate(32px, 3px) rotate(-35deg);
  }

   @keyframes rotate {
    100%{    transform: rotate(360deg)}
  }

}

.b-example-divider {
  height: 3rem;
  background-color: rgba(0, 0, 0, .1);
  border: solid rgba(0, 0, 0, .15);
  border-width: 1px 0;
  box-shadow: inset 0 0.5em 1.5em rgba(0, 0, 0, .1), inset 0 0.125em 0.5em rgba(0, 0, 0, .15);
}

.text-container {
  overflow: hidden;
  height: 30px; /* Adjust based on your text size */
  position: relative;
}

.text-slide {
  animation: slidePauseSteps 8s linear forwards;
  white-space: nowrap;
}

@keyframes slidePauseSteps {
  0%, 8.33% { /* Start and pause for 1 second (1/12 of 8s) */
    transform: translateY(0%);
    opacity: 1;
  }
  12.5% { /* Slide 16.67% (1/6 of the container height) */
    transform: translateY(-15.67%);
    opacity: 1;
  }
  /* Following the same pattern for the next steps */
  16.67%, 25% { transform: translateY(-15.67%); opacity: 1; }
  29.17% { transform: translateY(-32.33%); opacity: 1; }
  33.33%, 41.67% { transform: translateY(-32.33%); opacity: 1; }
  45.83% { transform: translateY(-48%); opacity: 1; }
  50%, 58.33% { transform: translateY(-48%); opacity: 1; }
  62.5% { transform: translateY(-65.67%); opacity: 1; }
  66.67%, 75% { transform: translateY(-65.67%); opacity: 1; }
  79.17% { transform: translateY(-83.33%); opacity: 1; }
  83.33%, 100% { transform: translateY(-83.33%); opacity: 1; } /* Stay on the last step */
}