@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/mixins";

// @import "~bootswatch/dist/yeti/variables";
@import "./variables.scss";
@import "~bootstrap/scss/bootstrap";
@import "~bootswatch/dist/yeti/bootswatch";

@import "./components/calendar-row/calendar-row.scss"; @import "./components/condition-card/condition-card.scss"; @import "./components/diagnoses-card/diagnoses-card.scss"; @import "./components/info-icon/info-icon.scss"; @import "./components/navigation-card/navigation-card.scss"; @import "./components/overlay/overlay.scss"; @import "./components/page-header/page-header.scss"; @import "./components/page-navigation/page-navigation.scss"; @import "./components/right-panel/right-panel.scss"; @import "./components/side-nav/side-nav.scss"; @import "./components/site-layout/site-layout.scss"; @import "./components/social-card/social-card.scss";
@import "./views/find-care/find-care.scss"; @import "./views/home/home.scss"; @import "./views/login/login.scss"; @import "./views/my-data/my-data.scss"; @import "./views/my-health/my-health.scss"; @import "./views/perscription/perscription.scss"; @import "./views/social-groups/social-groups.scss"; @import "./views/sync/sync.scss";

@import "~intro.js/introjs.css";

body {

  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#4951ff+0,4951ff+6,6e74ff+14,ffffff+28,ffffff+100 */
  @include media-breakpoint-down(md) {
    background: linear-gradient(to bottom, #4951ff 0%, #4951ff 100px, #6e74ff 220px, #ffffff 350px, #ffffff 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  }
}

#root {
  font-family: 'Noto Sans', sans-serif;
  position: relative;
  min-height: 100vh;
}